import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DateTime} from 'luxon';
import {ReservationService} from '../../core/reservation.service';
import { Problem, Doctor, NEXT_DAY_HOUR} from '../types/types';
import {lastValueFrom} from "rxjs";
import {NotificationService} from "../../core/notification.service";

@Component({
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss']
})
export class TimeComponent implements OnInit {
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();

  today = DateTime.local();
  problem: Problem = {} as Problem;

  selectedDate: DateTime = DateTime.local();
  selectedTime: { hour: number, minute: number } | undefined;
  selectedDoctor: Doctor | undefined;
  selectedDoctorTimeSlots: { hour: number, minute: number }[] = [];
  availableTimeSlots: { hour: number, minute: number }[] = [];
  availableDoctors: Doctor[] = [];

  eventList: any[] | undefined = [];
  filteredEvents: any[] = [];

  isLoading = true;

  constructor(private reservationService: ReservationService, private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.reservationService.problemSubject.subscribe(async (resp) => {
      this.problem = resp;
      this.availableDoctors = this.problem.doctor;
      await this.getCalendarEvents();
      this.problem.doctor && this.selectDoctor(this.problem.doctor[0].email);
    });

    this.setSelectedDate();
  }

  setSelectedDoctorTimeSlots() {
    this.selectedDoctorTimeSlots = [];
    const startTime = DateTime.fromObject({
      hour: this.selectedDoctor?.reservationInfo?.start.split(':')[0] as unknown as number,
      minute: this.selectedDoctor?.reservationInfo?.start.split(':')[1] as unknown as number
    });
    const endTime = DateTime.fromObject({hour: 19, minute: 0});

    let currentTime = startTime;

    while (currentTime <= endTime) {
      this.selectedDoctorTimeSlots.push({hour: currentTime.hour, minute: currentTime.minute});
      currentTime = currentTime.plus({minutes: this.selectedDoctor?.reservationInfo?.interval});
    }
  }

  selectTime(timeSlot: { hour: number, minute: number }) {
    this.selectedTime = timeSlot;
  }

  async selectDay(date: DateTime) {
    this.isLoading = true;
    if (date.month !== this.selectedDate.month || date.year !== this.selectedDate.year) {
      this.selectedDate = date;
      await this.getCalendarEvents();
      this.filterEvents();
    } else {
      this.selectedDate = date;
      this.filterEvents();
    }

    this.isLoading = false;
  }

  selectDoctor(doctorEmail?: string) {
    this.isLoading = true;
    this.selectedDoctor = this.availableDoctors.find(doctor => doctor.email === doctorEmail) || new Doctor();
    this.setSelectedDoctorTimeSlots();
    this.reservationService.doctorSubject.next(this.selectedDoctor);
    this.filterEvents();
    this.isLoading = false;
  }

  clickNext() {
    // this.gaService.event('select_time', 'reservation', `${this.selectedDate} - ${this.selectedTime}`);
    this.next.emit();
    this.reservationService.timeSubject.next(this.selectedDate?.set({
      hour: this.selectedTime?.hour,
      minute: this.selectedTime?.minute,
      second: 0,
      millisecond: 0
    })!!);
  }

  clickBack() {
    this.setSelectedDate();
    this.selectedTime = undefined;
    this.selectedDoctor = undefined;
    this.availableDoctors = [];
    this.availableTimeSlots = [];
    // this.gaService.event('select_time_back', 'reservation');
    this.back.emit();
  }

  async getCalendarEvents() {
    try {
      const getEvents$ = await this.reservationService.getEvents(this.selectedDate.startOf('month')!!, this.selectedDate.endOf('month')!!);
      const resp = await lastValueFrom(getEvents$);
      this.eventList = resp.filter((event: any) => event.status !== 'cancelled');
    } catch (err) {
      this.notificationService.showErrorModal('Sajnáljuk, valami hiba történt!')
      this.eventList = undefined;
      console.log(err)
    }
  }

  async filterEvents() {
    if (this.selectedDoctor && this.eventList) {
      this.filteredEvents = this.eventList
        .filter(event => {
            const pendingDoctor = this.getEventDescriptionDoctorEmail(event)
            return this.selectedDoctor?.email === event.creator.email || this.selectedDoctor?.email === pendingDoctor || event.attendees?.map((attendee: any) => attendee.email).includes(this.selectedDoctor?.email)
          }
        )
        .filter(event => {
          const eventStart = DateTime.fromISO(event.start.dateTime || event.start.date, {zone: event.start?.timeZone});

          return eventStart.day === this.selectedDate?.day && eventStart.month === this.selectedDate?.month && eventStart.year === this.selectedDate?.year;
        });

      this.removeConflictingSlots();
    }
  }

  removeConflictingSlots() {
    if (this.selectedDate.weekday > 5) {
      this.availableTimeSlots = [];
      return;
    }

    this.availableTimeSlots = this.selectedDoctorTimeSlots.filter(slot => {
      const slotStart = DateTime.fromObject({
        year: this.selectedDate?.year,
        month: this.selectedDate?.month,
        day: this.selectedDate?.day,
        hour: slot.hour,
        minute: slot.minute
      });
      const slotEnd = slotStart.plus({minute: this.problem.duration});

      // Check if the slot conflicts with any event
      return !this.filteredEvents.some(event => {
        const eventStart = DateTime.fromISO(event.start.dateTime || event.start.date, {zone: event.start?.timeZone}).set({second: 0, millisecond: 0});
        const eventEnd = DateTime.fromISO(event.end.dateTime || event.end.date, {zone: event.start?.timeZone}).set({second: 0, millisecond: 0});
        return slotStart < eventEnd && slotEnd > eventStart;
      });
    });
  }

  setSelectedDate (): void {
    const endOfWeek = DateTime.local().plus({day: 1}).weekday === 5;
    const dayNumber = endOfWeek ? 4 : 2;
    this.selectedDate = DateTime.local().set({minute: 0, hour: 5}).plus({day: this.today.hour >= NEXT_DAY_HOUR ? dayNumber : 1});
  }

  private getEventDescriptionDoctorEmail(event: any): string {
    let email = '';
    try {
      email = event.description ? JSON.parse(event?.description).doctor.email : '';
    } catch (e) {
    }

    return email;
  }
}
