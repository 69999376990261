import { Component, OnDestroy, OnInit } from '@angular/core';
import Stepper from 'bs-stepper';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { MetaService } from '../core/meta.service';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit, OnDestroy {

  stepper: Stepper | undefined;
  currentIndex: number = 0;

  constructor(private gaService: GoogleAnalyticsService, private metaService: MetaService) {
  }

  ngOnInit(): void {
    this.stepper = new Stepper(document.querySelector('.bs-stepper')!!, {
      linear: true,
      animation: true
    });

    this.metaService.updateMetaTags('EREF fizioterápia - Foglalás', '/assets/images/og-r-image-temp-2.jpg', 'https://www.fizioeref.hu/foglalas');
    // this.gaService.pageView('/foglalas', 'Foglalás');
  }

  ngOnDestroy() {
    this.metaService.deleteMetaTags();
  }

  next() {
    this.currentIndex = (this.stepper as any)._currentIndex + 1;
    this.stepper?.next();

    if (this.currentIndex >= 2) {
      document.getElementsByClassName('bs-stepper-header')[0].scroll({
        left: 100,
        behavior: 'smooth'
      });
    }
    window.scrollTo(0, 0);
  }

  back() {
    this.stepper?.previous();
    this.currentIndex = (this.stepper as any)._currentIndex - 1;

    if (this.currentIndex <= 3) {
      document.getElementsByClassName('bs-stepper-header')[0].scroll({
        left: 0,
        behavior: 'smooth'
      });
    }
    window.scrollTo(0, 0);
  }

}
