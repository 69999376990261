export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyDbgNQr9D2wmIHP8LsXXOFSepV-SmayOU8",
    authDomain: "fizioeref-d5be6.firebaseapp.com",
    projectId: "fizioeref-d5be6",
    storageBucket: "fizioeref-d5be6.appspot.com",
    messagingSenderId: "95667949066",
    appId: "1:95667949066:web:c5b9d1f9bc5a3c634059f3",
    ssl: false,
  }
};
