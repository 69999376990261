import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Problem, ProblemList} from '../types/types';
import { ReservationService } from '../../core/reservation.service';
import {NotificationService} from "../../core/notification.service";

@Component({
  selector: 'app-problem',
  templateUrl: './problem.component.html',
  styleUrls: ['./problem.component.scss']
})
export class ProblemComponent implements OnInit {
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();
  selectedProblem: Problem | undefined;
  problemList: Problem[] = ProblemList;

  constructor(private reservationService: ReservationService, private notificationService: NotificationService) {}

  ngOnInit(): void {
  }

  selectProblem(problem: Problem) {
    this.selectedProblem = problem;
  }

  clickNext() {
    if(this.selectedProblem?.name === 'Gerincjóga') {
      this.notificationService.showInfoModal('Jelenleg jógaórára csak telefonon keresztül lehetséges időpontot foglalni. Kérjük keresse jógaoktatónkat. <br/> <br/>' +
        '<p class="flex gap-4 w-full justify-center"><span class="font-bold">Müller Judit:</span> <a href="tel:+36304808813">+3630/480-8813</a></p><br/> ')
      return;
    }

    if (this.selectedProblem) {
      this.reservationService.problemSubject.next(this.selectedProblem);
      // this.gaService.event('select_problem', 'reservation', this.selectedProblem.name);
      this.next.emit();
    }
  }

  clickBack() {
    this.back.emit();
  }

}
