import { Component, OnInit } from '@angular/core';
import { AvailableDoctors } from '../reservation/types/types';
import { AdminService } from '../core/admin.service';
import { Location } from '@angular/common';
import { NotificationService } from '../core/notification.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  doctors = AvailableDoctors;
  client: unknown;

  constructor(private adminService: AdminService, private location: Location,
              private notificationService: NotificationService, private titleService: Title) {
  }


  ngOnInit() {
    this.titleService.setTitle('EREF fizioterápia - Admin oldal');

    this.client = google.accounts.oauth2.initTokenClient({
      client_id: '764704778892-hsukhbejm18omr7ce518d91ijp90d9i4.apps.googleusercontent.com',
      scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/userinfo.email',
      callback: (response: any) => this.handleCredentialResponse(response),
    });
  }

  async handleCredentialResponse (resp: any) {
   this.adminService.getUserInfo(resp.access_token).subscribe((user: any) => {
     const signInEmail = (user as any).email;
     const accessToken = resp.access_token;

     if (this.doctors.find(doctor => doctor.email === signInEmail)) {
       this.adminService.setAccessToken(accessToken);
       this.adminService.setEmail(signInEmail);
       this.location.back();
     } else {
       this.notificationService.showErrorModal('Az email cím nem megfelelő.')
     }
   });
  }

  async login() {
    (this.client as any).requestAccessToken();
  }
}
