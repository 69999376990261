import { Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {DateTime} from "luxon";
import {NEXT_DAY_HOUR} from "../types/types";

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  currentYear: number;
  currentMonth: number;
  @Input() selectedDate: DateTime | undefined;
  @Input() isLoading: boolean = false;
  @Output() selectedDateEmitter = new EventEmitter<DateTime>();

  daysOfWeek = ['H', 'K', 'Sz', 'Cs', 'P', 'Sz', 'V'];
  months = [
    'Január', 'Február', 'Március', 'Április', 'Május', 'Június',
    'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'
  ];

  daysInMonth: number[] = [];
  leadingEmptyDays: number[] = [];

  availableDays: number[] = [];
  isDisabledNextDay: boolean = false;

  constructor() {
    const today = new Date();
    this.currentYear = today.getFullYear();
    this.currentMonth = today.getMonth();
  }

  ngOnInit() {
    this.calculateDays();
  }

  // Calculate the days and leading empty days in the current month
  calculateDays() {
    const firstDayOfMonth = new Date(this.currentYear, this.currentMonth, 1).getDay();
    const daysInCurrentMonth = new Date(this.currentYear, this.currentMonth + 1, 0).getDate();

    this.leadingEmptyDays = Array((firstDayOfMonth + 6) % 7).fill(0);  // Adjust for Monday start
    this.daysInMonth = Array.from({length: daysInCurrentMonth}, (_, i) => i + 1);
    this.availableDays = this.daysInMonth.filter(day => DateTime.local().set({
      month: this.currentMonth + 1,
      year: this.currentYear,
      day
    }).weekday <= 5 && !this.isToday(day) && !this.isBeforeToday(day) && !this.isEndOfToday(day));
  }

  previousMonth() {
    this.currentMonth--;
    if (this.currentMonth < 0) {
      this.currentMonth = 11;
      this.currentYear--;
    }
    this.calculateDays();
  }

  nextMonth() {
    this.currentMonth++;
    if (this.currentMonth > 11) {
      this.currentMonth = 0;
      this.currentYear++;
    }
    this.calculateDays();
  }

  isToday(day: number): boolean {
    const today = new Date();
    return (
      today.getDate() === day &&
      today.getMonth() === this.currentMonth &&
      today.getFullYear() === this.currentYear
    );
  }

  isBeforeToday(day: number): boolean {
    if (this.currentYear === DateTime.local().year && this.currentMonth + 1 === DateTime.local().month) {
      const today = DateTime.local();
      return today > today.set({day});
    }

    return this.currentYear < DateTime.local().year || this.currentYear === DateTime.local().year && this.currentMonth + 1 < DateTime.local().month
  }

  isEndOfToday(day: number): boolean {
    const today = DateTime.local();
    const tomorrow = today.plus({day: 1});

    return tomorrow.day === day  && today.hour >= NEXT_DAY_HOUR && today.month === this.currentMonth + 1;
  }

  selectDate(day: number, month: number, year: number) {
    if (!this.availableDays.includes(day) || this.isLoading) {
      return;
    }
    this.selectedDate = DateTime.fromObject({year, month: month + 1, day});
    this.selectedDateEmitter.emit(this.selectedDate);
  }
}

