import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {VideoService} from "../core/video.service";
import {cloneDeep, union} from "lodash";
import {Video} from "../shared/types/types";
import {MetaService} from "../core/meta.service";
import {GoogleAnalyticsService} from "ngx-google-analytics";

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
  videoList: Video[] = [];
  filteredVideos: Video[] = [];
  activeFilter: string = '';
  categories: string[] = [];

  constructor(private sanitizer: DomSanitizer, private videoService: VideoService, private metaService: MetaService, private gaService: GoogleAnalyticsService) {
    this.metaService.updateMetaTags('EREF fizioterápia - Videótár', '/assets/images/video.jpg', 'https://www.fizioeref.hu/videotar');
    // this.gaService.pageView('/videotar', 'Videótár');
  }

  async ngOnInit() {
    const videos = await this.videoService.getVideosFromContentful();
    this.videoList = videos.map(video => {
      const sanitizedLink = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + (video.link as string).replace('https://youtu.be/', '').split('?')[0])
      this.categories = union(this.categories, video.categories);

      return {...video, link: sanitizedLink}
    });

    this.filteredVideos = cloneDeep(this.videoList);
  }

  setActiveFilter(filter: string) {
    this.activeFilter = this.activeFilter === filter ? '' : filter;
    this.filteredVideos = this.activeFilter.length > 0 ? this.videoList.filter(video => video.categories?.includes(this.activeFilter)) : this.videoList;
  }
}
