<div class="content-space">
  <div class="place">
    <div>
      <h2>Helyszín</h2>
      <div class="divider"></div>

      <p>
        8000 Székesfehérvár, Budai út 46. 2/5. (OMV töltőállomással szemben)</p>
      <p><b>Kapucsengő:</b> 2/5 </p>
      <p><b>Parkolás:</b> Az épület körül az Interspar és az OMV kút parkolójában, valamit a Sarló<br/>
        utcában ingyenes parkolási lehetőség biztosított. A Gáz utcában fizetős parkolási<br/>
        lehetőség (sárga övezet) található.</p>
    </div>
    <div class="image">
      <a
        href="https://www.google.com/maps/place/Sz%C3%A9kesfeh%C3%A9rv%C3%A1r,+Budai+%C3%BAt+46,+8000/@47.1912739,18.4191239,17z/data=!4m6!3m5!1s0x4769f7bb2e5d1509:0x6ae34c47e1e70c51!8m2!3d47.1909896!4d18.4202826!16s%2Fg%2F11c22lp1nm?entry=ttu"
        target="_blank">
        <img src="assets/images/map.webp" alt="map"/></a>
    </div>
  </div>
  <div class="contact">
    <h2>Elérhetőségeink</h2>
    <div class="divider"></div>
    <b>Email</b>
    <ul>
      <li>
        <a href="mailto:fizioeref@gmail.com">fizioeref@gmail.com</a>
      </li>
    </ul>
    <b>Gyógytornászaink</b>
    <ul>
      <li><a href="tel:+36308336933">Enesei Rita <img src="assets/icons/phone.png" alt="phone" width="20"/> +3630/833-6933</a></li>
      <li><a href="tel:+36703603850">Meleghegyi Fanni <img src="assets/icons/phone.png" alt="phone" width="20"/> +3670/360-3850</a></li>
    </ul>
    <b>Masszőreink</b>
    <ul>
      <li><a href="tel:+36304068473">Kaufer Barbara <img src="assets/icons/phone.png" alt="phone" width="20"/> +3630/406-8473</a></li>
    </ul>
  </div>
</div>
