<div class="text-center">
  <div>
    <h1>Válassza ki az alkalmas időpontot!</h1>
    <p class="my-4">Az időpontfoglalás csak előzetes, annak elfogadásáról e-mail értesítést küldünk Önnek!</p>
    <div class="md:flex">
      <app-calendar (selectedDateEmitter)="selectDay($event)" [selectedDate]="selectedDate" [isLoading]="isLoading"></app-calendar>
      <div class="pt-4 md:pt-7 p-4 flex-1">
        <div class="flex-col md:flex-row flex gap-3" *ngIf="availableDoctors && selectedDoctor">
          <div *ngFor="let doctor of availableDoctors" class="flex gap-2 border-2 pl-2 md:pl-1 pr-2 py-1 cursor-pointer" [ngClass]="{'border-primary': selectedDoctor.email === doctor.email}"
          (click)="!isLoading && selectDoctor(doctor.email)">
            <div class="hidden md:block profile-image" [ngStyle]="{'background-image': 'url(assets/images/' + doctor.image + ')'}" ></div>
            <div class="text-left">
              <h4 class="text-md">{{doctor.name}}</h4>
              <p class="text-xs pb-1">{{doctor.type}}</p>
            </div>
          </div>
        </div>
        <div *ngIf="!isLoading && !selectedDoctor?.isAccountable" class="flex items-center my-4 gap-2 bg-secondary-light p-2 text-xs"><img src="assets/icons/info.svg" width="16" alt="info-icon">Munkatársunk csak magánszemély részére tud kiállítani számlát.</div>
        <div *ngIf="isLoading" class="flex m-auto w-full mt-10 justify-center items-center">
         <app-loading ></app-loading>
        </div>
        <div *ngIf="!isLoading">
          <div class="text-left my-4">Kiválasztott dátum: <b>{{selectedDate.year}}.{{selectedDate.month | number: '2.0-0'}}.{{selectedDate.day | number: '2.0-0'}}.</b></div>
          <div *ngIf="availableTimeSlots.length > 0" class="flex flex-wrap">
            <div class="time-slot" (click)="selectTime(time)" [ngClass]="{'selected': selectedTime === time}"
                 *ngFor="let time of availableTimeSlots">{{time.hour}}:{{time.minute | number: '2.0'}}</div>
            </div>
          <div *ngIf="availableTimeSlots.length=== 0" class="mt-10">
            <h6>Sajnáljuk, erre a napra nincs szabad időpont.</h6>
          </div>
          </div>
        </div>
      </div>
    </div>
</div>
<div class="btn-container">
  <button class="default-button" (click)="clickBack()">Vissza</button>
  <button class="primary-button" [disabled]="!selectedTime?.hour && !selectedTime?.minute" (click)="clickNext()">Tovább</button>
</div>
