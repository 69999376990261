import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {MetaService} from "../../core/meta.service";
import {BlogPost} from '../../shared/types/types';
import {ActivatedRoute, Router} from '@angular/router';
import {BlogService} from '../../core/blog.service';
import {Title} from '@angular/platform-browser';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss']
})
export class BlogPostComponent implements OnInit, OnDestroy {
  blogPost: BlogPost | undefined;
  blogPosts: BlogPost[] = [];
  title: string = '';
  sub$ = new Subscription();

  constructor(private router: Router, private blogService: BlogService, private activatedRoute: ActivatedRoute,
              private titleService: Title, private gaService: GoogleAnalyticsService, private metaService: MetaService) {
  }

  async ngOnInit() {
    await this.blogService.getBlogPosts();
    this.sub$.add(this.activatedRoute.params.subscribe(resp => {
      this.title = resp['title'];
      this.blogPost = this.blogService.getBlogPostByTitle(this.title);

      if (this.blogPost) {
        this.titleService.setTitle(`EREF fizioterápia - ${this.blogPost.title}`);
        this.metaService.updateMetaTags(`EREF fizioterápia - ${this.blogPost.title}`, `https:${this.blogPost.image}`, `https://fizioeref.hu/blog/${this.title}`, this.blogPost.description);
        // this.gaService.pageView(`/blog/${this.title}`, `Blog - ${this.blogPost.title}`);

        this.blogPosts = this.blogService.getRandomBlogPosts(this.blogPost.title).slice(0, 2);
      }
    }));
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }
}
