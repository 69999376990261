import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {FacebookEventName} from "../shared/types/types";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private fns: AngularFireFunctions) {}

  sendFacebookEvent(eventName: FacebookEventName, fbc: string) {
    const callable = this.fns.httpsCallable('sendFacebookEvent');
    return callable({eventName, fbc});
  }
}
