import {Component, OnInit} from '@angular/core';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {ActivatedRoute} from '@angular/router';
import {ModalService} from 'angular-modal-simple';
import {RitaModalComponent} from './rita-modal/rita-modal.component';
import {FanniModalComponent} from './fanni-modal/fanni-modal.component';
import {Title} from '@angular/platform-browser';
import {BarbiModalComponent} from "./barbi-modal/barbi-modal.component";
import {JuditModalComponent} from "./judit-modal/judit-modal.component";

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor(private gaService: GoogleAnalyticsService, private modalService: ModalService,
              private activatedRoute: ActivatedRoute, private titleService: Title) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('EREF fizioterápia - Munkatársaink');
    // this.gaService.pageView('/munkatarsaink', 'Munkatársaink');
    this.activatedRoute.url.subscribe(url => url[1] ? this.showModal(url[1].path) : {});
  }

  showModal(name: string) {
    switch (name) {
      case 'enesei-rita':
        this.modalService.display(RitaModalComponent);
        break;
      case 'meleghegyi-fanni':
        this.modalService.display(FanniModalComponent);
        break;
      case 'kaufer-barbara':
        this.modalService.display(BarbiModalComponent);
        break;
      case 'muller-judit':
        this.modalService.display(JuditModalComponent);
        break;
      default:
        break;
    }
  }
}
