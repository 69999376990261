import { Component, OnInit } from '@angular/core';

import{ init, send } from 'emailjs-com';
import {AnalyticsService} from "../../core/analytics.service";
import {lastValueFrom} from "rxjs";
init("user_XWOQ5YoYrFJNvXVWpS0jQ");

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {

  email: string = '';
  text: string = '';
  loading: boolean = false;

  constructor(private analyticsService: AnalyticsService) {
  }

  ngOnInit(): void {
  }

  sendEmail(): void {
    const templateParams = {
      clientEmail: this.email,
      message: this.text
    };

    this.loading = true;

    send('service_jareqdo', 'template_jpjviik', templateParams)
      .then(async (resp) =>  {
        this.email = '';
        this.text = '';
        this.loading = false;

        const fbc = sessionStorage.getItem('fbclid')
        if(!!fbc) {
          const sendFacebookEvent$ = this.analyticsService.sendFacebookEvent('Contact', fbc);
          await lastValueFrom(sendFacebookEvent$);
        }

        alert("Kérdését megkaptuk, hamarosan válaszolunk!");
      }, err => {
        this.loading = false;
        alert("Valami probléma adódott, kérjük próbálja meg újra.");
      });
  }
}
