<div class="text-center">
  <h1>Miben segíthetünk?</h1>
  <p class="my-4">Kérjük válasszon egy kategóriát az alábbiak közül.</p>
  <div class="problem-content">
    <div *ngFor="let problem of problemList" (click)="selectProblem(problem)"
         [ngClass]="{'selected': selectedProblem === problem}">
      <img src="assets/icons/{{problem.icon}}" [alt]="problem.icon"/>
      {{problem.name}}
      <b class="pl-2 text-red-700" *ngIf="problem.name === 'Gerincjóga'">ÚJ!</b>
    </div>
  </div>
</div>
<div class="btn-container">
  <div></div>
  <button class="primary-button" [disabled]="!selectedProblem" (click)="clickNext()">Tovább</button>
</div>
