import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  constructor(private gaService: GoogleAnalyticsService, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('EREF fizioterápia - Információk');
    // this.gaService.pageView('/informaciok', 'Információk');
  }

}
