<div class="p-4">
  <div class="flex justify-between items-center p-4">
    <button (click)="previousMonth()" class="text-gray-500 hover:text-black">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
      </svg>
    </button>
    <div class="text-lg font-semibold text-gray-800">{{ currentYear }} {{ months[currentMonth] }}</div>
    <button (click)="nextMonth()" class="text-gray-500 hover:text-black">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
      </svg>
    </button>
  </div>

  <div class="grid grid-cols-7 text-center text-gray-500 gap-1 mb-2">
    <div *ngFor="let day of daysOfWeek">{{ day }}</div>
  </div>

  <div class="grid grid-cols-7 text-center md:h-72 gap-1">
    <div *ngFor="let empty of leadingEmptyDays">&nbsp;</div>
    <div
      *ngFor="let day of daysInMonth"
      class="py-1 px-2 cursor-pointer flex items-center justify-center"
      [ngClass]="{
       'disabled-day': !availableDays.includes(day),
        'hover:bg-secondary-light': !isToday(day) && availableDays.includes(day),
        'bg-secondary-light': selectedDate?.day === day && selectedDate?.month === currentMonth + 1 && selectedDate?.year === currentYear
      }"
      (click)="selectDate(day, currentMonth, currentYear)">
      {{ day }}
    </div>
  </div>
</div>
