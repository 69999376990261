import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private accessToken: string = '';
  private loginEmail: string = '';

  constructor(private http: HttpClient) {
    this.accessToken = localStorage.getItem('token') ?? '';
    this.loginEmail = localStorage.getItem('email') ?? '';
  }

  setAccessToken(token: string): void {
    this.accessToken = token;
    localStorage.setItem('token', token);
  }

  getAccessToken(): string {
    return this.accessToken;
  }

  setEmail(email: string) {
    this.loginEmail = email;
    localStorage.setItem('email', email);
  }

  getEmail(): string {
    return this.loginEmail;
  }

  getUserInfo(accessToken: string): any {
    const url = 'https://www.googleapis.com/oauth2/v3/userinfo';
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`);

    return this.http.get(url, { headers });
  }
}
