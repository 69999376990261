<div class="profile">
  <div class="header">
    <div class="image"></div>
    <div class="info">
      <h2>Müller Judit</h2>
      <div class="divider"></div>
      <p class="flex items-center"><img src="assets/icons/doctor.png" width="20" alt="doctor"/>jógaoktató</p>
      <p><a href="tel:+36304808813"><img src="assets/icons/phone.png" alt="phone" width="20"/> +3630/480-8813</a>
      </p>
    </div>
  </div>
  <div class="text-content">
    <p>Lassan 10 éve tartok csoportos gerincjóga órákat. Ha van terület, ahol az önkiteljesedést, a
      kreativitást, a másoknak hasznos szolgálatot, törődést meg lehet élni, akkor ez számomra -az anyaság
      mellett - a jógaoktatás.<br/><br/>
      Minden órára való felkészülés egy kreatív folyamat. Csodálatos érzés látni azt, ahogy egy kezdetben
      zárkózottabb, merevebb, adott esetben bátortalanabb ember a jóga segítségével – s általam -
      nyitottabbá, rugalmasabbá, kitartóbbá és bátrabbá válik. A jógával és a gerinc mozgatásával lelki
      síkon is érezhetsz változást.<br/><br/>
      A helyes gyakorlás élvezetes és minden alkalommal örömteli hatású. Felszabadít, megmutatja
      önmagad által emelt korlátokat. Néhány héten belül érezheted a változást nem csupán a testedben,
      de közérzetedben, tudatosságodban is. Várlak szeretettel!</p>
  </div>
</div>
