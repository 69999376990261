import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReservationService } from '../core/reservation.service';
import { lastValueFrom } from 'rxjs';
import { DateTime } from 'luxon';
import { NotificationService } from '../core/notification.service';
import { AvailableDoctors, PendingEvent } from '../reservation/types/types';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss']
})
export class CancelComponent implements OnInit {
  eventId: string | null = '';
  event: any;
  patientEmail: string = '';
  isLoading: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private reservationService: ReservationService,
              private notificationService: NotificationService, private router: Router, private gaService: GoogleAnalyticsService) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.eventId = this.activatedRoute.snapshot.paramMap.get('id');
    // this.gaService.pageView('/lemondas', 'Lemondás');
    this.getEvent();
  }

  async getEvent() {
    if (this.eventId) {
      const pendingEvent$ = this.reservationService.getEventById(this.eventId);
      this.event = await lastValueFrom(pendingEvent$);

      if (this.event.attendees) {
        this.patientEmail = this.event.attendees.find((guest: any) => !AvailableDoctors.some(doctor => doctor.email === guest.email))?.email;
      } else {
        this.patientEmail = (JSON.parse(this.event.description) as PendingEvent).patient.email;
      }

      const isToday = DateTime.fromISO(this.event.start.dateTime).diff(DateTime.local(), 'days');
      if (isToday.days <= 1) {
        this.notificationService.showErrorModal('A foglalás 24 órán belüli lemondása a weboldalon keresztül már nem lehetséges. Kérjük keressen bennünket telefonon.', true);
        this.event = undefined;
      }
    }
    this.isLoading = false;
  }

  async cancelReservation() {
    this.isLoading = true;
    let doctor = '';
    if (this.event.attendees) {
      doctor = this.event.attendees.find((guest: any) => AvailableDoctors.some(doctor => doctor.email === guest.email)).email;
    } else {
      doctor = (JSON.parse(this.event.description) as PendingEvent).doctor.email;
    }

    const deleteEvent$ = this.reservationService.deleteEvent(this.event, doctor, this.patientEmail);
    try {
      await lastValueFrom(deleteEvent$);
      this.notificationService.showSuccessModal('Az időpontot sikeresen lemondta.', true);
      // this.gaService.event('cancel_reservation', 'reservation');
    } catch (e) {
      // this.gaService.event('cancel_reservation_error', 'reservation');
      this.notificationService.showErrorModal('Valami hiba történt. Kérjük próbálja újra, vagy keressen bennünket emailben vagy telefonon.');
    } finally {
      this.isLoading = false;
    }
  }

}
