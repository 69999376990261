<div class="circle">
  <b class="text-lg">i</b>
</div>
<div class="info-modal">
  <div class="title">
    <b>Info</b>
  </div>
  <p [innerHtml]="message"></p>
  <button (click)="close()" class="default-button">Rendben</button>
</div>
