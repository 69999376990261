import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from 'angular-modal-simple';

@Component({
  selector: 'app-judit-modal',
  templateUrl: './judit-modal.component.html',
  styleUrls: ['./judit-modal.component.scss']
})
export class JuditModalComponent implements OnInit, OnDestroy {
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.modalService.closeAll();

  }

  constructor(private router: Router, private modalService: ModalService) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.router.navigate(['/munkatarsaink']);
  }

}
